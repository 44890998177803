<template>
  <div>
    <b-row>
      <!--Tabs-->
      <b-col cols="12" class="mb-3">
        <b-tabs align="center">
          <b-tab
            v-for="(item, index) in status"
            :key="index"
            :title="item.label"
            @click="fetchOrders(item.value)"
          />
        </b-tabs>
      </b-col>
    </b-row>

    <MyTable
      :rows="rows"
      :columns="columns"
      :is-loading="isLoading"
      :has-export="true"
      :exporting="isExporting"
      @handleExport="handleReportExport"
    >
      <template v-slot="{ props }">
        <!-- ORDER ID -->
        <span v-if="props.column.field === 'reference_id'">
          <b-button
            variant="primary"
            class="w-100"
            :to="`/freight-forwarding/order-details/${props.row.id}`"
          >
            {{ props.row.reference_id }}
          </b-button>
        </span>

        <!--TYPE -->
        <span v-else-if="props.column.field === 'delivery_type'">
          {{ props.row.delivery_type + 'port' }}
        </span>

        <!--ORIGIN ADDRESS -->
        <span v-else-if="props.column.field === 'origin_address'">
          <span v-if="props.row.shipment_delivery_type === 'Door to Door'">
            {{ props.row.sender.complete }}
          </span>
          <span v-else> {{ props.row.pickup_port ? props.row.pickup_port.complete : '-' }} </span>
        </span>

        <!--DESTINATION ADDRESS -->
        <span v-else-if="props.column.field === 'destination_address'">
          <span v-if="props.row.shipment_delivery_type === 'Door to Door'">
            {{ props.row.recipient.complete }}
          </span>
          <span v-else>
            {{ props.row.dropoff_port ? props.row.dropoff_port.complete : '-' }}
          </span>
        </span>

        <!--PICKUP -->
        <span v-else-if="props.column.field === 'pickup_date'">
          {{ props.row.pickup_date ? $helpers.formatDate(props.row.pickup_date) : '-' }}
        </span>

        <!-- STATUS -->
        <span v-else-if="props.column.field === 'status'">
          <span>
            <b-badge :variant="props.row.status.color_name" class="w-100 p-1 font-weight-bolder">
              {{ getStatusText(props.row.status) }}
            </b-badge>
          </span>
        </span>

        <!-- AMOUNT -->
        <span v-else-if="props.column.field === 'amount'">
          <span> RM {{ props.row.payment ? props.row.payment : '0.00' }} </span>
        </span>

        <!-- ACTION -->
        <span v-else-if="props.column.field === 'actions'">
          <div v-if="currentStatus === 'history' || currentStatus === 'cancelled'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item disabled> No actions available </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-else>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('update-status', props.row)">
                Change status
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <!--Back to Dashboard Button-->
    <b-row>
      <b-col cols="12 text-center">
        <b-button variant="danger" :to="{ name: 'dashboard' }" class="mr-1">
          Back to Dashboard
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      ref="modal"
      :size="modalSize"
      :title="modalTitle"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        v-if="modalType === 'quotation document'"
        :order="selectedRow"
        class="overflow-auto d-flex align-items-center justify-content-center"
        @success="handleModalClosure"
      >
        <iframe
          v-if="selectedRow.quote && selectedRow.quote.document"
          :src="selectedRow.quote.document"
          frameborder="3"
          width="700"
          height="750"
          :allowfullscreen="true"
        />
        <div v-else class="text-center">No Document available.</div>
      </div>
      <UpdateStatus
        v-else-if="modalType === 'update-status'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
    </b-modal>
  </div>
</template>

<script>
import { STATUS_OPTIONS_FLEET } from '@/common/Constant'
import { MyTable } from '@/components'
import axios from '@axios'
import UpdateStatus from '@/components/Fleet/OrderTracker/UpdateStatus.vue'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'

export default {
  name: 'MyOrderTracker',
  components: {
    MyTable,
    UpdateStatus,
  },
  data() {
    const columns = [
      {
        label: 'Order ID',
        field: 'reference_id',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
      },
      {
        label: 'Type',
        field: 'delivery_type',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
        sortable: true,
      },
      {
        label: 'Delivery Type',
        field: 'shipment_delivery_type',
        thClass: 'text-center',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Company Name',
        field: 'sender.name',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
        sortable: true,
      },
      {
        label: 'Pick Up Date',
        field: 'pickup_date',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Origin Address',
        field: 'origin_address',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Destination Address',
        field: 'destination_address',
        thClass: 'text-center align-middle text-nowrap',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Amount',
        field: 'amount',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle text-center text-nowrap',
        sortable: false,
      },
      {
        label: 'Status',
        field: 'status',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle text-center text-nowrap',
        sortable: false,
      },
      {
        label: 'Actions',
        field: 'actions',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
    ]

    const status = [
      { label: 'Active', value: 'active' },
      { label: 'Scheduled', value: 'scheduled' },
      { label: 'Pending Payment', value: 'pending_payment' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'History', value: 'history' },
    ]

    return {
      columns,
      status,
      rows: [],
      modalType: null,
      currentStatus: 'active',
      selectedRow: [],
      isLoading: false,
      isEditable: true,
      refundType: null,
    }
  },
  computed: {
    modalTitle() {
      if (this.modalType === 'accept_declined') {
        return 'Payment Details'
      }

      return this.modalType ? this.modalType.replace('-', ' ').toUpperCase() : ''
    },
    modalSize() {
      const modals = ['update-status', 'edit-order', 'accept_declined']

      return modals.includes(this.modalType) ? 'md' : 'lg'
    },
    // eslint-disable-next-line vue/return-in-computed-property
    handleShowQuotation() {},
  },
  created() {
    this.fetchOrders('active')
  },
  methods: {
    async fetchOrders(type) {
      this.resetRow()
      this.isLoading = true
      const query = `type=${type}`
      const response = await axios.get(`order-tracker-fleet?${query}`)
      this.rows = response.data
      this.currentStatus = type
      this.isLoading = false
    },

    async handleAction(type, row, status = null) {
      const refundStatus = ['in-process', 'paid', 'decline-request', 'approved']
      if (refundStatus.includes(type)) {
        await axios.patch(`/update-refund-status/${row.refund.id}?status=${status}`)
        this.$swal({
          title: 'Update success!',
          icon: 'success',
          text: `You have updated the refund status of ${row.reference_id}`,
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        this.fetchOrders(this.currentStatus)
        return
      }

      this.modalType = type
      this.$refs.modal.show()
      this.selectedRow = row
    },

    async handleModalClosure() {
      await this.fetchOrders(this.currentStatus)
      this.$refs.modal.hide()
    },
    async resetRow() {
      this.rows = []
    },
    getStatusText(status) {
      const currentStatus = STATUS_OPTIONS_FLEET.find((item) => item.id === status.value)
      return currentStatus ? currentStatus.text : status.text
    },
    handleReportExport(type) {
      console.log(this.rows);
      const other = this.rows.map(item => ({
        // ...item,
        id: item.id,
        quantity: item.quantity,
        order_date: item.date_added,
        pickup_date: item.pickup_date,
        status: item.status.text,
        sender_name: item.sender.name,
        sender_contact: item.sender.phone,
        sender_address: item.pickup_port.complete,
        shipment_delivery_type: item.shipment_delivery_type,
        recipient_contact: item.recipient.phone,
        recipient_address: item.dropoff_port.complete,
      }))
      if (type == 'xlsx') {
        const ws = XLSX.utils.json_to_sheet(other)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'sheet')
        XLSX.writeFile(wb, `freight_export${Date.now()}.xlsx`)
      } else if(type == 'csv') {
        const blob = new Blob([Papa.unparse(other)], { type: 'application/vnd.ms-excel;charset=utf-8;' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', `freight_export${Date.now()}.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
  },
}
</script>
